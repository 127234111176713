import "./../App.css";

const Visit = () => {
  return (
    <main className="page-background paragraph-container">
      <h2 className="page-header">How to visit JungleBeauty Bengal Cattery</h2>
      <section className="section-text">
        <h3 className="page-subheader">
          We are always happy to meet our customers in our home
        </h3>
        <h3 className="page-subheader padded-header">
          To visit our cattery in person and see the kittens, we ask you to do
          the following:
        </h3>
        <ol className="list-container">
          <li>
            Please write a letter telling us a little about yourself and your
            experience with cats, especially Bengals. Also, please describe what
            kind of kitten you are looking for by colour, character and
            temperament.
          </li>
          <li>
            Please arrange an online meeting where you can see those kittens you
            are interested in for 15-20 minutes and discuss possible questions.
          </li>
          <li>
            After the online meeting, we will send you a deposit agreement if
            you are interested in the special kitten. Please sign the deposit
            agreement to reserve a specific kitten and pay the deposit of 400
            CAD.
          </li>
          <li>Next, agree on a meeting time within the next three days.</li>
          <li>
            During the personal meeting, you can see the kitten and play with
            them if they have received two standard sets of vaccinations. This
            session lasts 30-40 minutes.
          </li>
          <li>
            If you are not satisfied with the selected kitten, you can replace
            them with another one or refuse to purchase them and get back the
            entire deposit amount.
          </li>
          <li>
            After three days from the moment of booking a kitten, the deposit
            amount is not refundable.
          </li>
        </ol>
        <p>
          Thus, visiting our cattery is impossible without a preliminary online
          meeting and making a deposit.
        </p>
        <p>
          Understanding is very much appreciated as many people want to come and
          see the kittens without any intention of buying one or presenting any
          ID.
        </p>
        <p>
          For us, each visit means preparation and then sanitization of the
          rooms. There are a lot of viruses and bacteria, many of which are
          carried on people's shoes and clothes.
        </p>
        <p>Exceptions can be discussed in unique cases.</p>
      </section>
    </main>
  );
};

export default Visit;
