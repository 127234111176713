import { Row, Col } from "react-bootstrap";
import "./../App.css";

const PrepareForKitten = () => {
  return (
    <main className="page-background paragraph-container">
      <h2 className="page-header">
        How to be ready for your new kitten from JungleBeauty Bengals Cattery
      </h2>
      <section className="section-text">
        <ol className="list-container">
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  You need a carrier for the kitten to bring them home safely
                  and take them to the vet clinic later on. We recommend having
                  a carrier big enough for a full grown cat. It can be a soft
                  carrier or a plastic carrier. We suggest having a double door
                  carrier; here is an example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/top-load%20carrier.jpg"
                  alt="Top-load cat carrier"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>You need a litter box for the kitten. For example:</p>
                <p>
                  Note: our kittens are trained to use different litter boxes -
                  from simple tray to open top to enclosed.
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/cat_litter_box.jpg"
                  alt="Litter box"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  You need litter for the kitten. For example, Clumping Wood
                  Litter from ökocat:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/ococat.jpg"
                  alt="Bag of ökocat litter"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>You need a scratching post for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/scratchinPost.jpg"
                  alt="Scratching post"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>You need a sleeping bed for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/sleepingBed.jpg"
                  alt="Cat bed"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  You need 2 or more bowls for water. We recommend ceramic or
                  stainless bowls. For example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/ceramic bowls for kitten.jpg"
                  alt="Ceramic food and water bowls on a stand"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  Kittens like the Cat Drinking Fountain. Many cats and
                  especially Bengals love running water. That is why they prefer
                  to drink from the tap or fountains. It also encourages them to
                  drink more which is very important for cat health. Just do not
                  forget to refresh the water every 1-2 days. For example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/cat_water_fountain.jpg"
                  alt="Cat drinking from a water fountain"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>Toys for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/kitten_toys.jpg"
                  alt="Feather and fuzzy worm toys, wand toys with feathers on strings, and a tube for cats to crawl through"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>Laser pointer toy for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/laser_toy.jpg"
                  alt="Laser pointer"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>Cat tree for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/cat_tree.jpg"
                  alt="Cat lying on top of multi-level cat tree"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  Heating pad for the kitten. Just recommended. We would
                  recommend buying such a pad with settings for 80-130℉. For
                  example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/heating_pad.jpg"
                  alt="Puppy lying on heating pad"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>Brush for the kitten. For example:</p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/CatBrush.jpg"
                  alt="Cat being brushed"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  Nail care: trim front paw nails every 2-3 weeks. We found
                  toenail clippers for people work best for this purpose. Please
                  buy a high quality item to avoid discomfort and injury. For
                  example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/toenailClipper.jpg"
                  alt="Toenail clipper"
                />
              </Col>
            </Row>
          </li>
          <li>
            <Row className="item-container">
              <Col>
                <p>
                  The exercise wheel is one of the most popular items for
                  Bengals. They absolutely love it. However, you can buy it
                  later on when the kitten is over one year old. For example:
                </p>
              </Col>
              <Col xl={3}>
                <img
                  className="side-img"
                  src="/assets/site/ready/OneFastCat.jpg"
                  alt="Cat running on exercise wheel"
                />
              </Col>
            </Row>
          </li>
        </ol>
      </section>
      <section className="section-text">
        <h3 className="page-subheader">Food and water</h3>
        <ol className="list-container">
          <li>
            <p>
              Dry Royal Canin: Mother and Baby Cat and Kitten formulas - will be
              provided from us at first.
            </p>
            <div className="item-container">
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/royal_canin_mother_baby_cat.jpg"
                alt="Bag of Royal Canin Mother & Baby Cat dry food"
              />
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/royal_canin_kitten.jpg"
                alt="Bag of Royal Canin Kitten dry food"
              />
            </div>
          </li>
          <li>
            <p>
              Canned Royal Canin: Mother and Baby Cat and Kitten formulas -
              samples will be provided by us for the first few days.
            </p>
            <div className="item-container">
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/wet_mother_baby.jpg"
                alt="Can of Royal Canin Mother & Baby Cat wet food"
              />
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/wet_kitten.jpg"
                alt="Can of Royal Canin Kitten wet food"
              />
            </div>
          </li>
          <li>
            <p>
              Our kittens have tried the raw diet which is recommended for
              Bengal Cats. We will share more details on pick up time. Food
              samples will be provided. We recommend Big Country Raw with Pure
              Chicken and IronWillRaw with Basic Chicken.
            </p>
            <div className="item-container">
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/BigCountryRaw_PureChicken.jpg"
                alt="Box of Big Country Raw Chicken"
              />
              <img
                className="side-img bottom-img"
                src="/assets/site/ready/IronWillRaw.jpg"
                alt="Box of Iron Will Raw Chicken"
              />
            </div>
          </li>
        </ol>
      </section>
      <section className="section-text">
        <h3 className="page-subheader">How our kittens are fed</h3>
        <ol className="list-container">
          <li>Water is available at all times.</li>
          <li>Dry food is available at all times.</li>
          <li>
            Canned food is given as is and given 3 times per day (3-4 soup
            spoons per portion).
          </li>
          <li>
            Raw food is given 3 times per day. Total amount is approximately 10%
            of kitten's weight per day. For example, if the kitten weighs 2kg,
            you can give them 200 grams per day divided into 3 portions for
            breakfast, lunch, and dinner.
          </li>
          <li>
            We would recommend not feeding the kitten with canned and raw food
            at the same time. Please choose which diet you want to proceed with.
          </li>
        </ol>
      </section>
      <section className="section-text">
        <h3 className="page-subheader">Best supplements for the kitten</h3>
        <ol className="list-container">
          <li>Probiotic</li>
          <li>Taurine</li>
          <li>Vitamins</li>
        </ol>
      </section>
      <section className="section-text">
        <h3 className="page-subheader">Environment</h3>
        <p>
          Bengals are very energetic cats. They love to jump everywhere and use
          everything to play; it is their nature. Thus it is good to have
          surfaces (tables, countertops, shelves) clear of any items with which
          they are not allowed to play :-)
        </p>
      </section>
    </main>
  );
};

export default PrepareForKitten;
