import { Link } from "react-router-dom";

const ArticleList = [
  {
    title: `The Best Age to Adopt a Bengal Kitten`,
    date: "2023-08-15",
    body: (
      <>
        <p>
          We often see requests to get a kitten at the age of 8-10 weeks, with
          some websites recommending this age for adoption.
        </p>
        <p>
          Beware it is very risky to adopt a Bengal kitten at that age, as they
          can easily get infected. Some breeders may find it easy to release a
          Bengal kitten at this age, but no responsible and reputable breeder
          should act this way. If you see that a breeder agrees or wants to
          release a kitten at this age, it is a big warning sign. Usually, it is
          a way to reduce the price, but an ethical breeder should never
          sacrifice the wellbeing of their animals for the sake of a lower price
          for the buyer (
          <a href="https://thebengalconnection.com/articles/bengal-cat-price/">
            https://thebengalconnection.com/articles/bengal-cat-price/
          </a>
          ).
        </p>
        <p>
          Weaning usually only starts at the age of 4-6 weeks in Bengal kittens
          and may last until 10-12 weeks or even later. Kittens consume less and
          less mother's milk but it is crucially important for them to receive
          some antibodies from the mother and build the immune response to
          viruses and bacteria with some support. The actual building of the
          kitten's immune system and immune response start during and continue
          after weaning, though some kittens may not be ready even after the
          second vaccination and need to be with their mom until 14 weeks of age
          or even later.
        </p>
        <p>
          From our experience, we see that adoption at the age of 8-10 weeks is
          very risky for Bengal kittens and may result in the kittens not
          surviving their immunity gap period. In the period of weaning and
          right after, Bengal kittens have an immunity gap period, which means
          they stop having a sufficient amount of mother's antibodies to protect
          them but have not yet developed the immune response to produce their
          own antibodies.
        </p>
        <p>
          If they face deadly viruses during this period, the illness may
          progress so quickly (1-2 days) that even veterinarians do not have
          enough time to help, and their little bodies may be so harmed that
          nothing can be done. Bengal kittens need very intensive attention and
          care during the immunity gap period which the average kitten owner may
          not be able to provide. The weakness should be identified right away
          as sometimes hours count, especially in the case of panleukopenia.
          Also, kittens have small bodies and can easily lose too much fluid in
          diarrhea and vomiting, and fade away from dehydration and lethargy.
        </p>
        <p>
          To summarize, Bengal kittens are very vulnerable and defenceless at
          the age of 8-12 weeks, which is why most cat organizations have
          ethical rules for breeders to release kittens after 12 weeks of age
          and at least two FVRCP vaccinations. However, it is common in Bengals
          for the immunity gap period to last up to 16-17 weeks of age. Thus, it
          is pretty safe to adopt a Bengal kitten at the age of 3.5 or 4 months.
          Responsible breeders will do it on request or at least provide 3-7
          days of general health warranty, which gives you the option to return
          the kitten at the first sign of weakness during the first days or week
          and get help from the breeder with no additional vet expenses.
        </p>
        <p>
          It is important to keep in mind that vaccination does not give 100%
          protection and works best if 3 sets of the FVRCP vaccine are given.
          Only 3 sets are considered full vaccination, and there is a reason for
          that. When the mother's milk is consumed, kittens cannot develop the
          full immune response as the mother's antibodies are prevalent. The
          first 1-2 FVRCP vaccines are usually given when Bengal kittens still
          consume mother's milk so the 3rd vaccine is needed to verify that the
          immune response is adequate and ensure the kitten is not getting sick
          after that. Adopting a Bengal kitten older than 4 months of age gives
          your kitten more protection and you can be more confident that the
          kitten has a strong immune system.
        </p>
        <p>
          You can asses the kitten by appearance and behaviour. A health kitten
          is active, willingly interacts with people, has fast reactions, a
          clean thick coat, and clean eyes, nose, and ears. Bengal kittens are
          usually very muscular, and can easily run and jump up and down,
          gracefully landing on their paws. Healthy Bengals use the litter box
          with no problem, are alert enough to hunt, and play with almost
          everything they can find.
        </p>
        <p>
          Kittens from a good breeder are usually well-socialized and do not
          have problems adjusting to their new home and new owners if they are
          more mature. It is a big myth that only young kittens can accept
          people as their parents, but it is not true. Cat and kittens of any
          age can love and trust new owners if they do not feel threatened by
          humans at an early age. The problem occurs when they have a bad
          experience with people, which is why it is important to research
          breeders before adopting a Bengal kitten, as even nice-looking kittens
          may have behavioural problems.
        </p>
      </>
    ),
  },

  /*
    {
        title: ``,
        body:
            <>
                <p>

                </p>
            </>
    }
    */
];

export default ArticleList;
